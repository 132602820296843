import { HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListItemManagementService } from 'src/app/components/mdm/openapi/api/listItemManagement.service';
import { ListItem } from 'src/app/components/mdm/openapi/model/listItem';
import {
    ApiFilterRequest,
  EntryItemResponse,
  GridSortDescriptor,
  ListItemLookup,
  SearchDataList
} from 'src/app/shared/shared-interfaces/shared-interfaces';
import { SettingsService } from 'src/app/shared/shared-services/settings/settings.service';
import { UtilitiesService } from 'src/app/shared/shared-services/utilities/utilities.service';
import { DateHelperService } from '../../../shared/shared-services/date-helper/date-helper.service';
import {
  BillEntitiesManagementService,
  BillEntity,
  CountyCode,
  CountyListService,
  HcpcCategory,
  HCPCListService,
  ListItemDtl,
  ListItemLookupValidationService,
  ListManagementService,
  NPIService,
  NPIv2,
  Product,
  ProductManagementService
} from '../openapi';
import { DEFAULT_MSG_KEY, NPI_LOOKUP } from '../../../shared/interceptors/default-error-message-token';

export enum CategoryType {
  PRISM = 'PRISM',
  CLAIM = 'CLAIM',
  FINANCE = 'FINANCE',
}

@Injectable()
export class MdmService {

  private cachedLookup: Map<string, ListItemLookup> = new Map();

  private cachedDataList: Map<string, Promise<ListItemDtl[]>> = new Map();

  constructor(
    private settingsService: SettingsService,
    private listItemManagementService: ListItemManagementService,
    private listItemLookupValidationService: ListItemLookupValidationService,
    private dateHelperService: DateHelperService,
    private listsService: ListManagementService,
    private utilities: UtilitiesService,
    private productsService: ProductManagementService,
    private npiService: NPIService,
    private hcpcListService: HCPCListService,
    private countyListService: CountyListService,
    private billEntitiesManagementService: BillEntitiesManagementService
  ) {
    this.listItemManagementService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.listItemManagementService.configuration.withCredentials = true;
    this.listItemLookupValidationService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.listItemLookupValidationService.configuration.withCredentials = true;
    this.listsService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.listsService.configuration.withCredentials = true;
    this.productsService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.productsService.configuration.withCredentials = true;
    this.npiService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.npiService.configuration.withCredentials = true;
    this.hcpcListService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.hcpcListService.configuration.withCredentials = true;
    this.countyListService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.countyListService.configuration.withCredentials = true;
    this.billEntitiesManagementService.configuration.basePath = `${this.settingsService.basePath}/ss/mdm`;
    this.billEntitiesManagementService.configuration.withCredentials = true;
  }

  public getListItemByCode(listTypeName: string, listTypeCode: string, asOfDate?: Date): Observable<ListItemLookup> {

    if (!asOfDate) {
      asOfDate = new Date();
    }
    const asOfString = this.dateHelperService.toISODate(asOfDate);
    const lookupKey = listTypeCode + '^' + listTypeCode + '^' + asOfString;
    const cachedItem = this.cachedLookup[lookupKey];
    if (cachedItem) {
      return of(this.cachedLookup[lookupKey]);
    }
    return this.listItemLookupValidationService.validateListItemCode(listTypeName, listTypeCode, asOfString).pipe(
      map(response => {
        if (response.active) {
          const listItemLookup = {
            listTypeName,
            listTypeCode,
            listTypeDesc: response.listDtlDesc,
            attributes: new Map(),
          };
          response.listDtlAttrs.forEach(respAttr => {
            listItemLookup.attributes.set(respAttr.name, respAttr.value);
          });
          this.cachedLookup[lookupKey] = listItemLookup;
          return listItemLookup;
        } else {
          return null;
        }
      })
    );
  }

  public getListItemByID(listId: number, listItemId: number): Observable<ListItemDtl> {
    return this.listItemManagementService.getListItemByID(listId, listItemId);
  }


  public getItemList(id: number, pageSize: number = 50, restartRowNum: number = 0): Observable<EntryItemResponse> {
    return this.listItemManagementService
      .getListItems(id, pageSize, restartRowNum, null, null, 'response')
      .pipe(map((response) => this.mapToEntryItemResponse(response)));
  }

  private mapToEntryItemResponse(response: HttpResponse<Array<ListItemDtl>>): EntryItemResponse {
    const entryItems = response.body.map((item) => {
      const now = new Date();
      const startDate = this.dateHelperService.fromISO(item.listDtlStartDt);
      const endDate = this.dateHelperService.fromISO(item.listDtlEndDt);
      const parsedEntryItem = {
        listDtlCode: item.listDtlCode,
        listDtlDesc: item.listDtlDesc,
        activeFromTo: this.dateHelperService.toRangeString(startDate, endDate),
        status: startDate.getTime() <= now.getTime() && (!endDate || endDate.getTime() >= now.getTime()),
        listDtlId: item.listDtlId,
        attributes: item.attributes ?? [],
      };

      (item.attributes ?? []).forEach(attribute => {
        parsedEntryItem[attribute.listAttrId] = attribute.listDtlAttrValue;
      });
      return parsedEntryItem;
    });
    return {
      restartRowNum: response.headers.get('Restart-Row-Id'),
      items: entryItems,
    };
  }

  getListById(listId: number): Observable<ListItem> {
    return this.listsService.getList(listId);
  }

  getLists(pageSize: number = 50, pageNumber: number = 0): Observable<HttpResponse<Array<ListItem>>> {
    return this.listsService.getLists(pageSize, pageNumber, 'response');
  }

  getCachedMdmDataList(listType: string, listTypeCtx?: string | '', activeAsOfDate?: Date, sort?: string,
                       dir: 'asc' | 'desc' = 'asc'): Observable<ListItemDtl[]> {
    const activeDateStr = activeAsOfDate ? this.dateHelperService.toISODate(activeAsOfDate) : this.dateHelperService.toISODate(new Date());
    const cacheKey = [listType, listTypeCtx, activeDateStr].join('_');
    const sortStr = sort ? `[{"property": "${sort}", "dir": "${dir}"}]` : null;
    if (!this.cachedDataList.has(cacheKey)) {
      this.cachedDataList.set(cacheKey, new Promise<ListItemDtl[]>((resolve, reject) => {
        this.listItemManagementService.getListItemsByName(listType, sortStr, null, 0, 50,
          listTypeCtx !== '' ? listTypeCtx : null, activeDateStr)
          .subscribe(resolve, reject);
      }));
    }
    return from(this.cachedDataList.get(cacheKey));
  }

  getCachedMdmDataListPaging(listType: string, listTypeCtx?: string | '', activeAsOfDate?: Date, sort?: string,
                             dir: 'asc' | 'desc' = 'asc', restartRowId: number = 0,
                             pageSize: number = 50, filter?: string, cacheData: boolean = true): Observable<ListItemDtl[]> {
    const activeDateStr = activeAsOfDate ? this.dateHelperService.toISODate(activeAsOfDate) : this.dateHelperService.toISODate(new Date());
    const cacheKey = [listType, listTypeCtx, activeDateStr, restartRowId].join('_');
    const sortStr = sort ? `[{"property": "${sort}", "dir": "${dir}"}]` : null;
    let filterStr: string;
    if (filter) {
      const filterHeader = [
        {property: 'ListDtlCode', operator: 'ilike', value: '%' + filter + '%', group: 1},
        {property: 'ListDtlDesc', operator: 'ilike', value: '%' + filter + '%', group: 1}
      ];
      filterStr = JSON.stringify(filterHeader);
    }
    if (!!cacheData) {
      if (!this.cachedDataList.has(cacheKey)) {
        this.cachedDataList.set(cacheKey, new Promise<ListItemDtl[]>((resolve, reject) => {
          this.listItemManagementService.getListItemsByName(listType, sortStr, filterStr, restartRowId, pageSize,
            listTypeCtx !== '' ? listTypeCtx : null, activeDateStr)
            .subscribe(resolve, reject);
        }));
      }

      return from(this.cachedDataList.get(cacheKey));
    } else {
      return this.listItemManagementService.getListItemsByName(listType, sortStr, filterStr, restartRowId, pageSize,
        listTypeCtx !== '' ? listTypeCtx : null, activeDateStr);
    }
  }

  private getMdmDataList(listType: string, listTypeCtx?: string | '', activeAsOfDate?: Date, filter?: string, pageSize?: number | 50,
                         restartRowId?: number | 0): Observable<EntryItemResponse> {
    const activeDateStr = activeAsOfDate ? this.dateHelperService.toISODate(activeAsOfDate) : this.dateHelperService.toISODate(new Date());
    return this.listItemManagementService.getListItemsByName(listType, null, filter, restartRowId, pageSize,
      listTypeCtx !== '' ? listTypeCtx : null, activeDateStr, 'response')
      .pipe(map((response) => this.mapToEntryItemResponse(response)));
  }


  public getProductList(sort: GridSortDescriptor[] = [], filter: string = '', restartRowNum: number = 0,
                        pageSize: number = 50): Observable<HttpResponse<Array<Product>>> {
    const sortString = this.buildSortString(sort);
    const filterHeader = [];
    if (filter > '') {
      filterHeader.push({property: 'wildSearch', operator: '=', value: '%' + filter + '%'});
    }
    const filterString = JSON.stringify(filterHeader);
    return this.productsService.getProducts(sortString, filterString, restartRowNum, pageSize, 'response');
  }

  public getProductsByName(codes: string[], sort: GridSortDescriptor[] = [], restartRowNum: number = 0,
                           pageSize: number = 50): Observable<HttpResponse<Array<Product>>> {
    const sortString = this.buildSortString(sort);
    const filterHeader = [{property: 'hcpcCatgTypeCode', operator: '=', value: 'PRISM', group: 1}];
    codes.forEach(code => {
      filterHeader.push({property: 'hcpcCode', operator: '=', value: code, group: 2});
    });
    const filterString = JSON.stringify(filterHeader);
    return this.productsService.getProducts(sortString, filterString, restartRowNum, pageSize, 'response');
  }


  private buildSortString(sort: GridSortDescriptor[]): string {
    const sortHeader = [];
    sort.forEach(sortEntry => {
      sortHeader.push({
        property: sortEntry.field,
        dir: sortEntry.dir
      });
    });
    return JSON.stringify(sortHeader);
  }

  public getProductListDataForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    const filter = `[{"property": "wildSearch", "operator": "=", "value": "%${filterStr}%"}]`;
    return this.productsService.getProducts(null, filter)
      .pipe(map(products => {
        return products.map(product => {
          return {
            id: product.productId.toString(),
            displayName: product.hcpcCode + ' - ' + product.description,
            displaySelected: product.hcpcCode
          } as SearchDataList;
        });
      }));
  }

  public getSingleHcpcForCombobox(hcpcCode: string): Observable<SearchDataList> {
    return this.getHcpcListDataForComboboxPaging(hcpcCode, 0, 1).pipe(map(codes => {
      return codes.find(code => code.id === hcpcCode);
    }));
  }

  public getHcpcListDataForComboboxPaging(filterStr: string, restartRowId: number,
                                          pageSize: number): Observable<Array<SearchDataList>> {
    const filter = `[{"property": "wildSearch", "operator": "=", "value": "%${filterStr}%"}]`;
    return this.productsService.getProducts(null, filter, restartRowId, pageSize)
      .pipe(map(products => {
        return products.map(product => {
          return {
            id: product.hcpcCode,
            displayName: `${product.hcpcCode} - ${product.description}`,
            displaySelected: product.hcpcCode
          } as SearchDataList;
        });
      }));
  }


  public getProductListDataForComboboxPaging(filterStr: string, restartRowId: number,
                                             pageSize: number): Observable<Array<SearchDataList>> {
    const filter = `[{"property": "wildSearch", "operator": "=", "value": "%${filterStr}%"}]`;
    return this.productsService.getProducts(null, filter, restartRowId, pageSize)
      .pipe(map(products => {
        return products.map(product => {
          return {
            id: product.productId.toString(),
            displayName: `${product.hcpcCode} - ${product.description}`,
            displaySelected: product.hcpcCode
          } as SearchDataList;
        });
      }));
  }


  public getProductCategoriesListDataForCombobox(filterStr: string): Observable<Array<SearchDataList>> {
    return this.getHcpcCatgByWildSearch(filterStr)
      .pipe(map(hcpcCategories => {
        return hcpcCategories.map(hcpcCategory => {
          return {
            id: hcpcCategory.catgCode.toString(),
            displayName: hcpcCategory.catgCode + ' - ' + hcpcCategory.catgDesc,
            displaySelected: hcpcCategory.catgCode.toString()
          } as SearchDataList;
        });
      }));
  }

  public getProductCategoriesListDataForComboboxPaging(filterStr: string, restartRowId: number,
                                                       pageSize: number): Observable<Array<SearchDataList>> {
    return this.getHcpcCatgByWildSearch(filterStr, 'PRISM', restartRowId, pageSize)
      .pipe(map(hcpcCategories => {
        return hcpcCategories.map(hcpcCategory => {
          return {
            id: hcpcCategory.catgCode.toString(),
            displayName: `${hcpcCategory.catgCode} - ${hcpcCategory.catgDesc}`,
            displaySelected: hcpcCategory.catgCode.toString()
          } as SearchDataList;
        });
      }));
  }

  public codeToDesc(mdmCode: string): string {
    let mdmDesc = '';
    mdmCode.split('_').forEach(statusWord => {
      mdmDesc = mdmDesc + statusWord.charAt(0).toUpperCase() + statusWord.slice(1).toLowerCase() + ' ';
    });
    return mdmDesc.trim();
  }

  public lookupNPIv2(npiNumber: string): Observable<NPIv2> {
    const context = new HttpContext().set(DEFAULT_MSG_KEY, NPI_LOOKUP);

    return this.npiService.lookupNPIV2(npiNumber, null, null, 'body', false, {context});
  }

  public getHcpcsWithCatgTypeForCombobox(restartRowId?: number, pageSize?: number, filter?: string,
                                         catgType?: string): Observable<Array<SearchDataList>> {
    const sort = JSON.stringify([{field: 'description', dir: 'asc'}]);
    const filterStr = filter ? JSON.stringify([{property: 'hcpcCode', operator: '=', value: filter}]) : null;
    return this.hcpcListService.getHcpcCodes(sort, restartRowId, pageSize, filterStr, null, catgType)
      .pipe(map(codes => {
        return codes.map(code => {
          return {
            id: code.hcpcCode,
            displayName: code.hcpcCode + ' - ' + code.hcpcShortDesc,
            searchName: code.hcpcCode
          } as SearchDataList;
        });
      }));
  }

  public getHcpcCodes(
    restartRowId: number, pageSize: number, filter?: ApiFilterRequest[], catgType?: string,
  ) {
    const sort = JSON.stringify([{field: 'description', dir: 'asc'}]);
    const filterStr = filter?.length ? JSON.stringify(filter) : null;
    return this.hcpcListService.getHcpcCodes(sort, restartRowId, pageSize, filterStr, null, catgType, false, 'response');
  }

  public getHcpcByCodeAndCatgTypeForCombobox(hcpcCode: string, catgType?: string): Observable<SearchDataList> {
    return this.getHcpcsWithCatgTypeForCombobox(0, 1, hcpcCode, catgType).pipe(map(codes => {
      return codes.find(code => code.id === hcpcCode);
    }));
  }

  public getCountiesByStateCode(stateCode: string, restartRowId: number = 0, pageSize: number = 300): Observable<Array<CountyCode>> {
    return this.countyListService.getCountiesByStateCode(stateCode, restartRowId, pageSize);
  }

  public getCountiesByStateCodeForCombobox(stateCode: string): Observable<Array<SearchDataList>> {
    return this.getCountiesByStateCode(stateCode)
      .pipe(map((codes: CountyCode[]) => {
        return codes.map((code: CountyCode) => {
          return {
            id: code.fipsCode.toString(),
            displayName: code.countyName
          } as SearchDataList;
        });
      }));
  }

  public getHcpcCatgsByWildSearch(filter: string, catgType: string = 'PRISM', restartRowId: number = 0,
                                  pageSize: number = 100): Observable<Array<HcpcCategory>> {
    const filterHeader = [];
    filterHeader.push({property: 'wildSearch', operator: 'in', value: `%${filter}%`});
    const filterString = JSON.stringify(filterHeader);

    return this.hcpcListService.getHcpcCatgByType(catgType, pageSize, restartRowId, filterString);
  }

  public getHcpcCatgByType(offset: number, pageSize: number, catgType: CategoryType, filter: ApiFilterRequest[]) {
    const filterString = filter?.length ? JSON.stringify(filter) : null;
    return this.hcpcListService.getHcpcCatgByType(catgType, pageSize, offset, filterString, 'response');
  }


  public getHcpcCatgsByTypeAndName(catgCodes: Array<string>, catgType: string = 'PRISM', restartRowId: number = 0,
                                   pageSize: number = 100): Observable<Array<HcpcCategory>> {
    const filterHeader = [];
    filterHeader.push({property: 'catgCode', operator: 'in', value: catgCodes.toString()});
    const filterString = JSON.stringify(filterHeader);

    return this.hcpcListService.getHcpcCatgByType(catgType, pageSize, restartRowId, filterString);
  }

  public getHcpcCatgByWildSearch(filter: string, catgType: string = 'PRISM', restartRowId: number = 0,
                                 pageSize: number = 100): Observable<Array<HcpcCategory>> {
    const filterHeader = [];
    filterHeader.push({property: 'wildSearch', operator: '=', value: '%' + filter + '%'});
    const filterString = JSON.stringify(filterHeader);

    return this.hcpcListService.getHcpcCatgByType(catgType, pageSize, restartRowId, filterString);
  }

  public saveListItem(listId: number, listItem: ListItemDtl): Observable<any> {
    return listItem.listDtlId ?
      this.listItemManagementService.updateListItem(listId, listItem.listDtlId, listItem) :
      this.listItemManagementService.addListItems(listId, listItem);
  }

  public getListCodesForCombobox(listType: string, filter: string, pageSize?: number | 50,
                                 restartRowId?: number | 0, suppressCode?: boolean,
                                 listCtx?: string): Observable<Array<SearchDataList>> {
    return this.getMdmDataListWithWildSearch(listType, filter, restartRowId, pageSize, null, listCtx)
      .pipe(map(codes => {
        return codes.map(code => ({
          id: code.listDtlCode,
          displayName: suppressCode ? code.listDtlDesc : `${code.listDtlCode} - ${code.listDtlDesc}`
        }));
      }));
  }

  public getListCodesForComboboxPaging(listType: string, filter: string, restartRowId: number,
                                       pageSize: number, suppressCode?: boolean): Observable<SearchDataList[]> {
    return this.getMdmDataListWithWildSearch(listType, filter, restartRowId, pageSize)
      .pipe(map(codes => {
        return codes.map(code => ({
          id: code.listDtlCode,
          displayName: `${!suppressCode ? code.listDtlCode + ' - ' : ''}${code.listDtlDesc}`
        } as SearchDataList));
      }));
  }

  public getListCodesForComboboxByCodeNames(listType: string, codeNames: Array<string>): Observable<SearchDataList[]> {
    return this.getCodesByName(listType, null, codeNames)
      .pipe(map(codes => {
        return codes.map(code => {
          return {
            id: code.listDtlCode,
            displayName: `${code.listDtlCode} - ${code.listDtlDesc}`
          } as SearchDataList;
        });
      }));
  }

  public getListCodeForCombobox(listType: string, codeName: string): Observable<SearchDataList> {
    return this.getListCodesForComboboxByCodeNames(listType, [codeName])
      .pipe(map(codes => codes.find(code => code.id === codeName)));
  }

  public getMdmDataListWithWildSearchBase(listType: string, filter: string, restartRowId: number | 0,
                                      pageSize: number | 50, activeAsOfDate?: Date,
                                      listCtx?: string) {
    let filterString: string = null;
    if (filter > '') {
      const filterHeader = [
        {property: 'ListDtlCode', operator: 'ilike', value: '%' + filter + '%', group: 1},
        {property: 'ListDtlDesc', operator: 'ilike', value: '%' + filter + '%', group: 1}
      ];
      filterString = JSON.stringify(filterHeader);
    }
    return this.getMdmDataList(listType, listCtx, activeAsOfDate, filterString, pageSize, restartRowId)
  }

  public getMdmDataListWithWildSearch(listType: string, filter: string, restartRowId: number | 0,
                                      pageSize: number | 50, activeAsOfDate?: Date,
                                      listCtx?: string): Observable<ListItemDtl[]> {
     return this.getMdmDataListWithWildSearchBase(
       listType, filter, restartRowId, pageSize, activeAsOfDate, listCtx,
     ).pipe(map(response => {
        return this.toListItemDtls(response);
      }));
  }

  public getCodesByName(listType: string, activeAsOfDate: Date, codeNames?: Array<string>): Observable<ListItemDtl[]> {
    const filterHeader = [];
    let filterString: string;
    let pageSize = 20;
    if (codeNames) {
      filterHeader.push({property: 'ListDtlCode', operator: 'in', value: codeNames.toString(), group: 1});
      filterString = JSON.stringify(filterHeader);
      pageSize = codeNames.length;
    }
    return this.getMdmDataList(listType, null, activeAsOfDate, filterString, pageSize)
      .pipe(map(response => {
        return this.toListItemDtls(response);
      }));
  }

  private toListItemDtls(response: EntryItemResponse): Array<ListItemDtl> {
    return response.items.map(item => {
      return {
        listDtlId: item.listDtlId,
        listDtlCode: item.listDtlCode,
        listDtlDesc: item.listDtlDesc,
        listDtlStartDt: item.activeFromTo,
        attributes: item.attributes
      };
    });
  }

  public getAllBilledEntities(filter: string, restartRowId: number, pageSize: number): Observable<Array<BillEntity>> {
    let filterHeader = [];
    if (filter !== '') {
      filterHeader = [
        {property: 'billEntityName', operator: 'like', value: filter + '%', group: 1}
      ];
    }
    const filterString = JSON.stringify(filterHeader);
    return this.billEntitiesManagementService.getBillEntities(null, filterString, restartRowId, pageSize);
  }

  public getBilledEntityByBillEntityID(billEntityId: number): Observable<BillEntity> {
    return this.billEntitiesManagementService.getBillEntityByID(billEntityId);
  }
}
