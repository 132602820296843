export enum FeeScheduleType {
  BASE = 'Base',
  PAYER = 'Payer',
  PROVIDER = 'Provider',
  ADMIN = 'Admin'
}

export enum BaseFeeSchedType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}


export enum FeeScheduleSearchColumnProperty {
  STATUS = 'status',
  BENEFITTYPECODE = 'benefitTypeCode',
  STATE = 'state',
  BASEFEESCHEDNAME = 'baseFeeSchedName',
  PAYERFEESCHEDNAME = 'payerFeeSchedName',
  PROVFEESCHEDNAME = 'provFeeSchedName',
  PAYERLOB = 'payerLobId',
  PAYERFEESCHEDULE = 'payerFeeSchedId',
  PROVIDERORG = 'provOrgId'
}

export enum FeeSchedMiscType {
  CLAIM = 'CLAIM'
}

export enum FeeScheduleAuditType {
  BASE_VERSION = 'Base Fee Sched Version',
  PAYER_VERSION = 'Payer Fee Sched Version',
  PROVIDER_VERSION = 'Provider Fee Sched Version',
  PROVIDER_ORG = 'Provider Fee Sched Org'
}

