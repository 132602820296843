import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  GridComponent as KendoGridComponent,
  PageChangeEvent,
  PageSizeItem,
  RowClassArgs,
  ScrollRequest,
  SelectableSettings,
  SelectionEvent
} from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  DropDownPageEvent,
  GridColumn,
  GridNoData,
  GridPageChangeEvent,
  GridSortDescriptor,
  GridSortOptions,
  PagingConfig,
  SearchDataList
} from '../../shared-interfaces/shared-interfaces';
import { fromEvent, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ButtonType, ButtonSize } from '../button/button.component';
import { ButtonRounded } from '@progress/kendo-angular-buttons';
import { get } from 'lodash';


export interface MovedRows {
  movedId: number;
  movedBeforeId: number;
}

export interface GridCellOptions {
  rowNum: number;
  columnNumber: number;
  options: SearchDataList[];
  allowLoadMore: boolean;
}

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};


export interface RowAction {
  icon?: string;
  iconImageUrl?: string;
  size?: ButtonSize;
  type: ButtonType;
  rounded: ButtonRounded;
  disabled?: boolean;
  additionalInput?: any;
  callback: (index: number, dataItem: any) => void
  canShow?: (dataItem: any) => boolean
}

@Component({
  selector: 'prism-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GridComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() columns: GridColumn[];
  @Input() rowActions: RowAction[];
  @Input() hideActions: boolean = true;
  @Input() search: string;
  @Input() height: number;
  @Input() pageable = true;
  @Input() pageSize: number;
  @Input() loading: boolean;
  @Input() sortable: GridSortOptions;
  @Input() sortableColumns: string[] = [];
  @Input() sort: GridSortDescriptor[];
  @Input() skip = 0;
  @Input() useParentHeight = false;
  @Input() selectable: SelectableSettings | boolean;
  @Input() selectedKeys: Array<any> = [];
  @Input() selectionKey: string;
  @Input() noData: GridNoData;
  @Input() gridPadding = 178.5;
  @Input() draggableRows = false;
  @Input() showDtlRow = false;
  @Input() dtlRowTemplate: TemplateRef<any>;
  @Output() selectionChange: EventEmitter<SelectionEvent> = new EventEmitter();
  @Output() sortChange: EventEmitter<Array<GridSortDescriptor>> = new EventEmitter<Array<GridSortDescriptor>>();
  @Output() pageChange: EventEmitter<GridPageChangeEvent> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() rowMoved: EventEmitter<MovedRows> = new EventEmitter();
  @ViewChild('gridComponent') gridComponent: KendoGridComponent;
  public searchValue = '';
  public page = 0;
  @Input() pageSizes: PageSizeItem[] = [
    {text: '25', value: 25},
    {text: '50', value: 50},
    {text: '100', value: 100}
  ];
  private gridCellOptions: GridCellOptions[] = [];
  private currentSubscription: Subscription;

  constructor(
    protected elementRef: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone) {
  }

  getDropDownOptionsForCell(rowNum: number, columnNum: number): any[] {
    if (!this.columns?.length) {
      return [];
    }
    if (this.columns[columnNum]?.additionalInput?.options) {
      return this.columns[columnNum].additionalInput.options;
    }
    const values = this.gridCellOptions.find(opt => opt.rowNum === rowNum && opt.columnNumber === columnNum);
    return values?.options ?? [];
  }

  setDropDownOptionsForCell(rowNum: number, columnNum: number, options: any[]): void {
    const index = this.gridCellOptions.findIndex(opt => opt.rowNum === rowNum && opt.columnNumber === columnNum);
    if (index < 0) {
      this.gridCellOptions.push({
        rowNum: rowNum,
        columnNumber: columnNum,
        options: options,
        allowLoadMore: true
      });
    } else {
      this.gridCellOptions[index].options = options;
    }
  }

  loadNextOptionsPageForCell(event: DropDownPageEvent, rowNum: number, columnNum: number): void {
    const options = this.gridCellOptions.find(opt => opt.rowNum === rowNum && opt.columnNumber === columnNum);
    if (!!options && !options.allowLoadMore) {
      return;
    }
    const config: PagingConfig = this.columns[columnNum].additionalInput?.pagingConfig;
    const pageIndicator = config.usePageNumberInCallback ? event.pageNumber : event.restartRowNumber;
    config.onPageCallback(event.filter, pageIndicator, event.pageSize).subscribe(data => {
      if (!options || options.options.length === 0) {
        this.setDropDownOptionsForCell(rowNum, columnNum, data);
      } else if (data.length > 0 && options.options[0].id !== data[0].id) {
        options.options.push(...data);
      }
      if (options) {
        options.allowLoadMore = data.length > 0 && options.options[0].id !== data[0].id;
      }
    });
  }

  getFieldType(column, dataItem) {
    const fieldType = column.getFieldTypeFromDataItem?.(dataItem) || column.type;
    return fieldType;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleDataChange(changes.data);
    this.handleDraggableRowsChange(changes.draggableRows);
  }

  private handleDataChange(dataChange: SimpleChange): void {
    if (!dataChange) {
      return;
    }

    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }

    if (this.draggableRows) {
      setTimeout(() => {
        this.currentSubscription = this.handleDragAndDrop();
      });
    }

    const pagingCols = this.getPagingColumns();

    if (pagingCols.length > 0) {
      const data = this.getDataFromChange(dataChange);
      data.forEach((_, rowIndex) => {
        if (!this.isGridCellOptionExists(rowIndex)) {
          this.loadNextOptionsPageForCellForPagingColumns(rowIndex, pagingCols);
        }
      });
    }
  }

  private getPagingColumns(): { colNum: number; pagingConfig: any }[] {
    return (
      this.columns
        ?.filter((col) => !!col.additionalInput?.pagingConfig)
        ?.map((col) => ({
          colNum: this.columns.indexOf(col),
          pagingConfig: col.additionalInput.pagingConfig,
        })) || []
    );
  }

  public getDynamicWidth(actions: any[]): number {
    const baseWidth = 50;
    const actionButtonWidth = 50;

    // Calculate width based on the number of actions
    return baseWidth + (actions.length * actionButtonWidth);
  }

  private getDataFromChange(dataChange: SimpleChange): any[] {
    let data = dataChange.currentValue;
    if (data.hasOwnProperty('data')) {
      data = data.data;
    }
    return data;
  }

  private isGridCellOptionExists(rowIndex: number): boolean {
    return this.gridCellOptions.some((x) => x.rowNum === rowIndex);
  }

  private loadNextOptionsPageForCellForPagingColumns(
    rowIndex: number,
    pagingCols: { colNum: number; pagingConfig: any }[]
  ): void {
    pagingCols.forEach((col) => {
      this.loadNextOptionsPageForCell(
        {
          pageSize: col.pagingConfig.pageSize,
          pageNumber: 0,
          restartRowNumber: 0,
          filter: '',
        },
        rowIndex,
        col.colNum
      );
    });
  }

  private handleDraggableRowsChange(draggableRowsChange: SimpleChange): void {
    if (!draggableRowsChange) {
      return;
    }

    if (this.currentSubscription && !draggableRowsChange.currentValue) {
      this.currentSubscription.unsubscribe();
    } else if (!this.currentSubscription && draggableRowsChange.currentValue) {
      this.currentSubscription = this.handleDragAndDrop();
    }
  }

  ngOnInit(): void {
    if (!this.height) {
      setTimeout(() => this.getDefaultHeight());
    }
  }

  public getDefaultHeight(): void {
    let layout;
    if (this.useParentHeight) {
      layout = this.elementRef.nativeElement.parentNode;
      this.height = layout.clientHeight;
    } else {
      layout = this.elementRef.nativeElement.closest('.layout-container');
      if (layout) {
        this.height = (layout.clientHeight - this.gridPadding);
      }
    }
  }

  getHeaderStyle(columnStyle: { [key: string]: string }): { [key: string]: string } {
    return {
      ...columnStyle,
      'background-color': '#F6F6F6'
    };
  }

  getStyle(columnStyle: { [key: string]: string }): { [key: string]: string } {
    if (columnStyle?.bold) {
      if (columnStyle.bold === 'true') {
        columnStyle = {
          ...columnStyle,
          'font-weight': '700'
        };
      }
      delete columnStyle.bold;
    }
    if (columnStyle?.cellBackground) {
      if (columnStyle.cellBackground !== '') {
        columnStyle = {
          ...columnStyle,
          'background-color': columnStyle.cellBackground
        };
      }
      delete columnStyle.cellBackground;
    }
    return columnStyle;
  }

  public getRowStyle(context: RowClassArgs): any {
    return {
      dragging: context.dataItem.dragging
    };
  }

  handleSortChange(event: Array<SortDescriptor>): void {
    let sortDescriptors: Array<GridSortDescriptor> = [];
    event.forEach(kSDescriptor => {
      if (kSDescriptor.dir) {
        sortDescriptors.push({field: kSDescriptor.field, dir: kSDescriptor.dir});
      }
    });

    if (this.sortableColumns.length) {
      sortDescriptors = sortDescriptors.filter((sortCol) =>
        this.sortableColumns.includes(sortCol.field)
      );
      if (!sortDescriptors.length) return;
    }

    this.sortChange.emit(sortDescriptors);
  }

  loadMore(event: PageChangeEvent): void {
    this.pageSize = event.take;
    this.pageChange.emit({skip: event.skip, take: event.take});
  }

  scrollTo(request: ScrollRequest): void {
    this.gridComponent.scrollTo(request);
  }

  rowClickHandler(row: unknown): void {
    this.rowClick.emit(row);
  }

  getContentFromDataItem(dataItem: any, column: any): any {
    if (typeof dataItem[column.field] === 'object' && column?.additionalInput?.additionalProperty && !!dataItem[column.field]) {
      return dataItem[column.field][column.additionalInput.additionalProperty];
    } else {
      return dataItem[column.field];
    }
  }

  handleSelectionChange($event: SelectionEvent): void {
    this.selectionChange.emit($event);
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => {
    });
    let draggedItemIndex;

    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(
        dragStart
          .pipe(
            tap(({dataTransfer}) => {
              try {
                const dragImgEl = document.createElement('span');
                dragImgEl.setAttribute(
                  'style',
                  'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;'
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData('application/json', '');
              } catch (err) {
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({target}) => {
            const row: HTMLTableRowElement = target as HTMLTableRowElement;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.data.data[draggedItemIndex];
            dataItem.dragging = true;
          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.data.data.splice(draggedItemIndex, 1)[0];
          const dropItemIndex = closest(e.target, (node) => node.tagName.toLowerCase() === 'tr').rowIndex;

          draggedItemIndex = dropItemIndex;
          this.zone.run(() =>
            this.data.data.splice(dropItemIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const movedItem = this.data.data[draggedItemIndex];
          if (this.selectionKey) {
            let nextId = null;
            if (this.data.data.length - 1 > draggedItemIndex) {
              // get next index
              const nextItem = this.data.data[draggedItemIndex + 1];
              nextId = nextItem[this.selectionKey];
            }
            this.rowMoved.emit({
              movedId: movedItem[this.selectionKey],
              movedBeforeId: nextId
            });
          }
          movedItem.dragging = false;
        })
      );
    });

    return sub;
  }

  public canShowDtlRow(items: any): boolean {
    return this.showDtlRow;
  }

  protected getField(dataItem: any, col: GridColumn): any {
    if (col.fieldAccessor) {
      return get(dataItem, col.fieldAccessor);
    }
    return get(dataItem, col.field);
  }

  public canShowAction(action: RowAction, dataItem: any): boolean {
    return action?.canShow ? action.canShow(dataItem) : true;
  }
}
