<kendo-treelist
  *ngIf="columns?.length"
  kendoTreeListExpandable
  [loading]="isLoading"
  [expandable]="true"
  [isExpanded]="isExpanded"
  [selectable]="selectable"
  [data]="data"
  [height]="height"
  [hasChildren]="hasChildren"
  [fetchChildren]="handleFetchChildren.bind(this)"
  [isSelected]="isSelected.bind(this)"
  (expand)="expandRow($event)"
  (collapse)="collapseRow($event)"
  (selectionChange)="handleRowClick($event)">
  <kendo-treelist-checkbox-column
    *ngIf="!selectable?.readonly && selectable?.useCheckboxes"
    [checkChildren]="true"
    [width]="55">
  </kendo-treelist-checkbox-column>
  <kendo-treelist-column *ngFor="let column of columns"
    [expandable]="column.expandable"
    [title]="column.title"
    [field]="column.field"
    [width]="getColumnWidth(column)">
    <ng-template kendoTreeListCellTemplate let-dataItem let-rowIndex="rowIndex" let-loading [ngSwitch]="column.type">
      <span class="px-1" *ngIf="dataItem.loadingChildren && column.expandable">
        <kendo-loader
          type="infinite-spinner"
          color="primary"
          size="small">
        </kendo-loader>
      </span>
      <span *ngSwitchCase="'icon-text'">
        <span *ngIf="dataItem.iconClass"
          [class]="dataItem.iconClass"></span>
        <img *ngIf="dataItem.iconImgSrc"
          class="img-responsive"
          [style.height.px]="dataItem.iconImgHeight"
          [src]="dataItem.iconImgSrc"
          alt="">
        {{dataItem.item[column.field]}}
      </span>
      <span *ngSwitchCase="'pill'">
        <prism-pill [status]="dataItem.item[column.field]"></prism-pill>
      </span>
      <span *ngSwitchCase="'text'">
        {{dataItem.item[column.field]}}
      </span>
      <span *ngSwitchCase="'form-control-number'" class="d-flex justify-content-between">
        <kendo-numerictextbox [placeholder]="column.additionalOptions?.placeholder"
                              [formControl]="dataItem.item.controls[column.field]"
                              [format]="column.additionalOptions?.textBoxFormat ?? ''"
                              [min]="column.additionalOptions?.min"
                              [hidden]="dataItem.item.controls.hidden.value.value"
                              [spinners]="column.additionalOptions?.showSpinners">
        </kendo-numerictextbox>
      </span>
      <span *ngSwitchCase="'form-control-input'"   class="d-flex justify-content-between">
        <kendo-textbox [placeholder]="column.additionalOptions?.placeholder"
                       [hidden]="!dataItem.item.controls.hidden.value.value"
                       [formControl]="dataItem.item.controls[column.field]" [disabled]="true">
        </kendo-textbox>
      </span>
      <span *ngSwitchCase="'form-control-lookup'" class="d-flex justify-content-between"
            (click)="preventExpandCollapse($event)" (keydown)="preventExpandCollapse($event)" (keyup)="preventExpandCollapse($event)">
        <prism-lookup [class]="column.additionalInput?.className || 'col-8'"
                      [formControl]="dataItem.item.controls[column.field]"
                      [listTypeName]="column.additionalInput.listTypeName"
                      [displayWithCode]="column.additionalInput?.displayWithCode"
                      [paging]="column.additionalInput?.lookupPaging"
                      [pageSize]="column.additionalInput?.lookupPageSize"
                      [filterable]="column.additionalInput?.filterable"
                      [cacheData]="column.additionalInput?.cacheData"
                      (valueDataChange)="column.additionalInput?.callback($event, dataItem, column.field)"
                      [excludeCodes]="column.additionalInput?.getExcludeCodes() || []"
                      [hidden]="!dataItem.item.controls.hidden.value.value"
        >
        </prism-lookup>
      </span>
      <span *ngSwitchCase="'form-control-html'" [innerHtml]="dataItem.item.controls[column.field]?.value | safeHtml"></span>
      <span *ngSwitchCase="'form-control-money'">
        {{dataItem.item.controls[column.field]?.value | currency}}
      </span>
      <span *ngSwitchDefault>{{dataItem.item[column.field]}}</span>
    </ng-template>
  </kendo-treelist-column>
</kendo-treelist>

<prism-modal title="Unselectable Items"
  [opened]="showModal"
  (cancel)="closeModal()"
  [actions]="modalActions">
  The following items have no {{minLevelText}}s and cannot be selected:
  <ul>
    <li *ngFor="let node of unselectableNodes">
      {{getItemTypeText(node.itemType)}} {{node.item[nameProperty]}}
    </li>
  </ul>
</prism-modal>
