import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { PayersService } from 'src/app/components/payers/services/payers.service';
import { UserRolesService } from 'src/app/components/users/services/user-roles.service';
import { LookUpListItemDtl, SearchDataList } from 'src/app/shared/shared-interfaces/shared-interfaces';
import { ListItemDtl, ListItemDtlAttr } from '../../../components/mdm/openapi';
import { MdmService } from '../../../components/mdm/services/mdm.service';
import { DropDownPagingUtil } from '../dropdown-paging-utils';

export const LOOKUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LookupComponent),
  multi: true
};

export type lookupComponentViewModes = 'dropdown' | 'multiselect' | 'radio';

@Component({
  selector: 'prism-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
  providers: [LOOKUP_CONTROL_VALUE_ACCESSOR]
})
export class LookupComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {
  @Input() clearButton = true;
  @Input() viewMode: lookupComponentViewModes = 'dropdown';
  @Input() textField = 'listDtlDesc';
  @Input() valueField = 'code';
  @Input() searchField = 'searchName';
  @Input() disabled = false;
  @Input() listTypeName: string;
  @Input() listTypeCtx: string;
  @Input() activeAsOfDate: Date = new Date();
  @Input() excludeCodes: Array<string> = [];
  @Input() includeCodes: Array<string> = [];
  @Input() includeWithAttributes: Array<{ attribute: string, value: any }> = [];
  @Input() radioName: string;
  @Input() placeholder = 'Select';
  @Input() displayWithCode = false;
  @Input() api: 'mdm' | 'acm' | 'roles' | 'accounts' | 'payers' = 'mdm';
  @Input() apiConfig: { [key: string]: any };
  @Input() sort: string;
  @Input() direction: 'asc' | 'desc';
  @Input() paging = false;
  @Input() pageSize = 0;
  @Input() cacheData = true;
  @Input() filterable = false;
  @Input() minRequiredChars = 0;
  @Output() valueChange = new EventEmitter<any>();
  @Output() valueDataChange = new EventEmitter<any>();
  // publish an event if the value is not in the data list so caller can validate
  @Output() valueIsInData = new EventEmitter<boolean>();
  protected data: LookUpListItemDtl[];
  protected rawData: LookUpListItemDtl[];
  private hasFirstWriteValue = false;
  private pagingUtil = new DropDownPagingUtil();

  public debug = false;
  public queryString = '';
  public queryStringSubs: Subject<void> = new Subject();
  private unsubscribe$ = new Subject<void>();

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  private innerValue: any;

  private subscription: Subscription;

  constructor(
    private mdmService: MdmService,
    private userRolesService: UserRolesService,
    private payersService: PayersService
  ) {
    this.subscription = this.queryStringSubs
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.getData();
      });
  }

  static mapListItems(listItems: ListItemDtl[]): LookUpListItemDtl[] {
    return listItems.map(LookupComponent.lookUpToListItemDtl);
  }

  static mapListItemsWithCustomizedDisplay(listItems: ListItemDtl[]): LookUpListItemDtl[] {
    return listItems.map(LookupComponent.lookUpToListItemDtlWithCustomizedDisplay);
  }

  static lookUpToListItemDtl(listItem: ListItemDtl): LookUpListItemDtl {
    return {
      listDtlId: listItem.listDtlId,
      listTypeId: listItem.listDtlId,
      attributes: listItem.attributes,
      contexts: listItem.contexts,
      listDtlCode: listItem.listDtlCode,
      listDtlDesc: listItem.listDtlDesc,
      listDtlEndDt: listItem.listDtlEndDt,
      listDtlStartDt: listItem.listDtlStartDt,
    } as LookUpListItemDtl;
  }


  static lookUpToListItemDtlWithCustomizedDisplay(listItem: ListItemDtl): LookUpListItemDtl {
    return {
      listDtlId: listItem.listDtlId,
      listTypeId: listItem.listDtlId,
      attributes: listItem.attributes,
      contexts: listItem.contexts,
      listDtlCode: listItem.listDtlCode,
      listDtlDesc: listItem.listDtlCode + ' - ' + listItem.listDtlDesc,
      listDtlEndDt: listItem.listDtlEndDt,
      listDtlStartDt: listItem.listDtlStartDt,
    } as LookUpListItemDtl;
  }

  ngOnInit(): void {
    if (this.paging) {
      this.pagingUtil = new DropDownPagingUtil();
      this.pagingUtil.pageSize = this.pageSize;
      this.pagingUtil.onScrollCallback = this.getData.bind(this);
    }
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.excludeCodes || changes.includeCodes) {
      this.applyFilters();
    }

    let reloadData = false;
    if (changes.listTypeName?.currentValue !== undefined && !changes.listTypeName.firstChange) {
      reloadData = true;
    }
    if (changes.listTypeCtx?.currentValue !== undefined && !changes.listTypeCtx.firstChange) {
      reloadData = true;
    }
    if (changes.activeAsOfDate?.currentValue !== undefined && !changes.activeAsOfDate.firstChange) {
      reloadData = true;
    }
    if (reloadData) {

      this.getData();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pagingUtil.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.fetchData().subscribe((res) => {
      this.rawData = res;
      this.applyFilters();
      this.usePreviousSelected();
      this.valueIsInData.emit(this.isSelectedValueInData());
    });
  }

  fetchData(): Observable<LookUpListItemDtl[]> {
    switch (this.api) {
      case 'accounts':
      case 'payers':
        return this.fetchPayersData();
      case 'roles':
        return this.fetchRolesData();
      case 'mdm':
      default:
        return this.fetchMdmData();
    }
  }

  fetchSingleItem(itemCode: string): Observable<ListItemDtl> {
    switch (this.api) {
      case 'mdm':
      default:
        return this.fetchSingleMdmItem(itemCode);
    }
  }

  private fetchSingleMdmItem(itemCode: string): Observable<ListItemDtl> {
    if (itemCode) {
      return this.mdmService.getListCodeForCombobox(this.listTypeName, itemCode).pipe(
        map((listItem: SearchDataList) => {
          return {
            listDtlCode: listItem.id,
            listDtlDesc: listItem.displayName
          } as ListItemDtl;
        })
      );
    }
  }

  fetchRolesData(): Observable<LookUpListItemDtl[]> {
    return this.userRolesService.getDataList(this.listTypeName, this.apiConfig);
  }

  fetchMdmData(): Observable<LookUpListItemDtl[]> {
    if (this.paging) {
      return this.mdmService.getCachedMdmDataListPaging(this.listTypeName, this.listTypeCtx, this.activeAsOfDate, this.sort, this.direction,
          this.pagingUtil.restartRowNumber, this.pageSize, this.queryString, this.cacheData).pipe(
        map((listItems: ListItemDtl[]) => {
          let newPage = this.displayWithCode ?
            LookupComponent.mapListItemsWithCustomizedDisplay(listItems) :
            LookupComponent.mapListItems(listItems);
          this.pagingUtil.allowLoadMore = newPage.length > 0;

          if (this.pagingUtil.pageNumber > 0) {
            newPage = this.data.concat(newPage);
          }
          return newPage;
        })
      );
    }

    return this.mdmService.getCachedMdmDataList(this.listTypeName, this.listTypeCtx, this.activeAsOfDate, this.sort, this.direction).pipe(
      map((listItems: ListItemDtl[]) => {
        if (this.displayWithCode) {
          return LookupComponent.mapListItemsWithCustomizedDisplay(listItems);
        }

        return LookupComponent.mapListItems(listItems);
      })
    );
  }

  fetchPayersData(): Observable<LookUpListItemDtl[]> {
    return this.payersService.getDataListForLookup(this.listTypeName, this.apiConfig, this.queryString).pipe(
      map(LookupComponent.mapListItems)
    );
  }

  expandDropdown(): void {
    if (this.paging) {
      this.pagingUtil.addScrollListener();
    }

    if (this.data.length === 0) {
      this.getData();
    }
  }

  private applyFilters(): void {
    if (this.rawData) {
      this.data = this.rawData.filter(item => {
        return !this.shouldExclude(item.listDtlCode) &&
          this.shouldInclude(item);
      });
    }
  }

  private usePreviousSelected(): void {
    if (Array.isArray(this.innerValue) && this.data?.length) {
      const codes = this.innerValue.map(item => item.listDtlCode);
      const selected = this.data.filter(dataItem => codes.includes(dataItem.listDtlCode));
      if (this.innerValue.length && selected.length) {
        this.innerValue = selected;
      }
    }
  }

  private shouldInclude(item: LookUpListItemDtl): boolean {
    return this.shouldIncludeByCode(item.listDtlCode) && this.shouldIncludeByAttr(item.attributes);
  }

  private shouldIncludeByCode(code: string): boolean {
    return this.includeCodes.length === 0 ? true : this.includeCodes.indexOf(code) >= 0;
  }

  private shouldIncludeByAttr(attributes?: Array<ListItemDtlAttr>): boolean {
    return this.includeWithAttributes?.length ?
      (attributes?.length ? attributes.some(attr => {
        return this.includeWithAttributes.some(incAttr => {
          return attr.listAttrName === incAttr.attribute && attr.listDtlAttrValue === incAttr.value;
        });
      }) : false) : true;
  }

  private shouldExclude(code: string): boolean {
    return this.excludeCodes.length === 0 ? false : this.excludeCodes.indexOf(code) >= 0;
  }

  get value(): any {
    return this.innerValue;
  }

  set value(value: any) {
    this.writeValue(value);
  }

  writeValue(value: any): void {
    if ((typeof value !== 'object' || value === null) && value !== this.innerValue) {
      this.innerValue = value;
      this.selectionChanged(value);
    } else if (this.viewMode === 'multiselect' && Array.isArray(value)) {
      this.innerValue = Array.isArray(this.data) && this.data.length > 0 ?
        this.data.filter(item => value.some(val => val.listDtlCode === item.listDtlCode)) : value;
      this.selectionChanged(value);
    } else if (this.viewMode === 'dropdown') {
      const selectedValue = Array.isArray(this.data) && this.data.length > 0 ?
        this.data.find(item => value === item.listDtlCode) : value;
      this.valueDataChange.emit(selectedValue);
    }
    this.hasFirstWriteValue = true;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  onBlur(): void {
    this.onTouchedCallback();

  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isItemSelected(item: LookUpListItemDtl): boolean {
    return Array.isArray(this.innerValue) ?
      this.innerValue.filter(val => val.listDtlCode === item.listDtlCode).length > 0 :
      this.innerValue === item;
  }

  public isItemDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    return itemArgs.dataItem.isReadOnly ? itemArgs.dataItem.isReadOnly : false;
  }

  queryStringfilterChange($event): void {
    if ($event.length >= this.minRequiredChars) {
      this.queryString = $event;
      this.queryStringSubs.next();
    }
  }

  selectionChanged(value: any): void {
    if (this.onChangeCallback && this.onChangeCallback !== noop) {
      this.onChangeCallback(value);
    }
    if (this.hasFirstWriteValue) {
      this.valueChange.emit(value);
      this.valueIsInData.emit(this.isSelectedValueInData());
    }
    if (this.viewMode === 'dropdown' && this.paging) {
      if (value) {
        this.fetchSingleItem(value)?.pipe(takeUntil(this.unsubscribe$)).subscribe(singleListItem => {
          this.data = [singleListItem];
        });
      } else {
        this.ngOnInit();
      }
    }
  }

  private isSelectedValueInData(): boolean {
    return !!this.data?.find(item => item.listDtlCode === this.value);
  }
}
